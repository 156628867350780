// Dependencies
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
// Hooks
import useMouse from '../hooks/useMouse'
// Components
import SEO from '../components/seo'
import Layout from '../components/layout'
import Picture from '../components/picture'

const ALPHABET = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

const SelectedClient = ({ name, projectLink, preview, setPreview }) => {

  const handleMouseEnter = () => {
    if (projectLink) {
      setPreview(projectLink)
    }
  }

  const handleMouseLeave = () => {
    setPreview(null)
  }

  return !projectLink ? (
    <span className="client-tag h1">
      {name}
    </span>
  ) : (
    <Link
      to={`/portfolio/${projectLink.slug}`}
      className="client-tag h1"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {name}
    </Link>
  )
}

const Preview = ({ cover, thumbnail }) => {

  const coords = useMouse();

  return (
    <div className="client-project-preview" style={{ top: coords.y, left: coords.x, opacity: coords.y > 10 ? 1 : 0, background: '#f2f2f2' }}>
      {cover ? (
        <Picture {...cover.fluid} />
      ) : (
        <Picture {...thumbnail.fluid} />
      )}
    </div>
  )
}

const Clients = ({ data, pageContext }) => {

  const homepage = data.allContentfulHomepage.edges[0].node
  const [preview, setPreview] = useState(null);

  function sortAlphabetically(items) {
    return (
      items.reduce((accumulator, currentItem) => {

        const itemWords = currentItem.name.split(' ');
        const name = itemWords[0] === 'The' ? itemWords[1] : currentItem.name;

        let group = name[0].toUpperCase();

        if (!accumulator[group]) {
          accumulator[group] = { group, children: [currentItem] }
        } else {
          accumulator[group].children.push(currentItem)
        }

        return accumulator;
      }, {})
    )
  }

  const selected = data.selectedClients.nodes;
  const allClients = sortAlphabetically(data.allClients.nodes);

  return (
    <Layout
      {...homepage}
      masthead={homepage.tagline}
      hasFooter
    >
      <SEO title={'Make–Ready | Clients'} />

      <section className="innerx4 container">
        <div className="client-wall">
          {selected && selected.map((client, clientIndex) => (
            <SelectedClient {...client} preview={preview} setPreview={setPreview} key={`selected-client-${clientIndex}`} />
          ))}
        </div>
      </section>

      <section className="innerx4 container">
        <ul className="client-directory">
          {ALPHABET.map((letter, index) => {

            const clients = allClients[letter];

            return (
              <li className="group" key={`group-${letter}`}>
                <span>{letter}</span>
                <ul>
                  {clients && clients.children.map((client, clientIndex) => (
                    <li key={`client-${clientIndex}`}>
                      {client.name}
                    </li>
                  ))}
                </ul>
              </li>
            )
          })}
        </ul>
      </section>

      {preview && (
        <Preview {...preview} />
      )}

    </Layout>
  )
}

Clients.propTypes = {
  data: PropTypes.object.isRequired
}

export default Clients

export const projectsQuery = graphql`
  query clientsQuery {
    # Tags as Clients
    selectedClients: allContentfulTag(filter: {type: {eq: "Client"}, isSelected: {eq: true}}, sort: {order: ASC, fields: name}) {
      nodes {
        name
        projectLink {
          slug
          thumbnail {
            fluid(maxWidth: 240) {
              src
              sizes
              srcSet
              aspectRatio
            }
          }
          cover {
            fluid(maxWidth: 240) {
              src
              sizes
              srcSet
              aspectRatio
            }
          }
        }
      }
    }
    allClients: allContentfulTag(filter: {type: {eq: "Client"}}, sort: {order: ASC, fields: name}) {
      nodes {
        name
      }
    }
    # General settings content
    allContentfulHomepage (
      limit: 1
    ) {
      edges {
        node {
          tagline
          contactDetails {
            childMarkdownRemark {
              html
            }
          }
          mapLink
          email
          telephone
          projectFooterCopy {
            childMarkdownRemark {
              html
            }
          }
          # Clients
          clientWall {
            name
            slug
            type
          }
        }
      }
    }
  }
`
